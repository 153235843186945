import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'

const NextArrow = props => {
  const { onClick } = props

  return (
    <div onClick={onClick}>
      <div className="arrow-container left">
        <ChevronLeft size={20} />
      </div>
    </div>
  )
}

const PrevArrow = props => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 1500)

  return (
    <div onClick={onClick}>
      <div className="arrow-container right">
        <ChevronRight size={20} />
      </div>
    </div>
  )
}

const AwardComponenet = props => {
  let { title, image } = props
  return (
    <div className="award-container">
      <GatsbyImage
        image={getImage(image)}
        style={{ minHeight: '100%', maxHeight: '400px', margin: '0 auto' }}
        alt={title}
      />
      <h4 className="title">{title}</h4>
    </div>
  )
}

const AwardsCertsSlider = props => (
  <StaticQuery
    query={graphql`
      query SiteAwardsCerts {
        allContentfulAwardsCertifications {
          edges {
            node {
              id
              title
              awardImage {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    `}
    render={data => {
      var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1300,
        speed: 300,
        slidesToShow: props.slidesToShow ? props.slidesToShow : 1,
        slidesToScroll: 1,
        nextArrow: <PrevArrow />,
        prevArrow: <NextArrow />,
        responsive: [
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      }
      return (
        <div className="award-outer-padding">
          <Slider {...settings}>
            {data.allContentfulAwardsCertifications.edges.map((item, i) => (
              <AwardComponenet
                key={i}
                title={item.node.title}
                image={item.node.awardImage}
              />
            ))}
          </Slider>
        </div>
      )
    }}
  />
)

export default AwardsCertsSlider
